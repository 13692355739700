import * as React from "react";
import { graphql } from "gatsby";
import { Link, withPrefix } from 'gatsby';
import {
  Layout, SEO, CBreadCrumb, LWrap, COverflowTable, CBtnList
} from "../../../components/_index"
import { size } from "lodash";
import { setLang } from "../../../utils/set-lang"

// markup
const SubPage = ({ data }: { data: any }) => {
  const frontmatter = data?.markdownRemark?.frontmatter
  return (
    <Layout isKv={false}>
      <SEO title={frontmatter?.title}
        description={frontmatter?.description}
      />
      <CBreadCrumb data={{
        parent: [
          {
            label: "ご利用規約",
            path: "/agreement/"
          }
        ],
        current: {
          label: frontmatter?.title.includes('-') ? frontmatter?.title.split('-')[0] : frontmatter?.title
        }
      }} />
      <section className="l_sect02">
        <LWrap>
          <h1 className="c_headingLv2">宿泊予約システム利用規約</h1>
          <p className="u_mbLarge">このページは三菱地所ホテルズ＆リゾーツ株式会社のグループホテルに対する宿泊予約システム利用規約のページです。宿泊予約システムのご利用に際しましては、必ずお読みいただき、同意いただけますようお願いいたします。<br /><br />三菱地所ホテルズ＆リゾーツ株式会社（以下｢当社｣）は、当社の運営する宿泊予約システム（以下｢本システム｣）を、お客様にご利用いただくにあたり、次の利用規約を設けさせていただいております。本規約にご同意のうえ、本システムをご利用いただけますよう、お願い申しあげます。</p>
          <section className="u_mbMedium">
            <h2 className="c_headingLv3">第1条（本システム利用に際し）</h2>
            <p>1. お客様には、インターネット利用の一般的なマナーや技術的ルールを守っていただきます。<br />
            2. 営利を目的とした本システムの利用は、事由の如何を問わず固くお断りします。</p>
          </section>
          <section className="u_mbMedium">
            <h2 className="c_headingLv3">第2条（利用規約に反する場合の措置）</h2>
            <p>第三者に迷惑・不利益を与える行為、当社のグループホテルのサービスの提供に支障をきたすおそれのある行為等当社が不適当と判断する行為をおこなうお客様には、本システム、当社のグループホテルのご利用をお断りいたします。</p>
          </section>
          <section className="u_mbMedium">
            <h2 className="c_headingLv3">第3条（お客様の利用システム環境）</h2>
            <p>本システムは、お客様の利用システムの環境と設定が適切になされていることを前提条件としております。この条件に当てはまらない方の動作結果やそれがもたらす諸影響に関して、当社は一切の責任を負いません。また、この条件を満たしていても、当社の管理の及ばない原因によって、本システムが正しく作動しない場合につきましても、それがもたらす影響に関して、当社は一切の責任を負いません。</p>
          </section>
          <section className="u_mbMedium">
            <h2 className="c_headingLv3">第4条（本システムご利用者とは）</h2>
            <p>本規約にご同意いただいたお客様のみが本システムをご利用になれます。また、本システムを利用して、ご予約をされた時点で、本規約にご同意いただいたとみなします。</p>
          </section>
          <section className="u_mbMedium">
            <h2 className="c_headingLv3">第5条（本システムの提供サービス）</h2>
            <p>当社が運営しているホームページ（以下、｢本ホームページ｣）上で、当グループホテルが提供する宿泊商品の一部の予約が行えます。</p>
          </section>
          <section className="u_mbMedium">
            <h2 className="c_headingLv3">第6条（当システム利用上の注意）</h2>
            <p>本システムの提供するサービスは、本ホームページ以外で提供するサービスに対して優位性を持つものではありません。満室等の理由によりご予約いただけない場合もあります。</p>
          </section>
          <section className="u_mbMedium">
            <h2 className="c_headingLv3">第7条（自己責任）</h2>
            <p>1. 本システムの利用にあたり、お客様の自らの行為およびご自身のメールアカウントよりなされた一切の行為およびその結果について、お客様ご自身の行為の有無、過失の有無を問わず、お客様はその責任を負うものとします。また、本システムのご利用にあたり、当社の過失に因る場合を除き、お客様が第三者に損害を与えた場合、お客様は自己の責任と負担において当該第三者との紛争を解決するものとします。<br />
            2. ご利用者が次の事項のいずれかに該当する行為により、当社、グループホテルに損害を与えた場合、当社、グループホテルは当該利用者に対して被った全ての損害の賠償を請求出来るものとします。<br />
            ・本システム利用規約、グループホテルが別に定める宿泊約款、利用規約に違反した場合。<br />
            ・有害なコンピュータープログラムの送信、書き込みを行った場合。<br />
            ・第三者の情報の送信、書き込みを行った場合。<br />
            ・お客様の私的利用以外の目的で、当社に無断で利用した場合。<br />
            ・その他日本国内で有効な法令に違反する行為を行った場合。
            </p>
          </section>
          <section className="u_mbMedium">
            <h2 className="c_headingLv3">第8条（予約の成立）</h2>
            <p>本システムを利用した宿泊予約の成立は、予約通知メールが送信された時といたします。</p>
          </section>
          <section className="u_mbMedium">
            <h2 className="c_headingLv3">第9条（予約の取り消しと変更）</h2>
            <p className="u_mb20">本システムを利用したグループホテルのご予約の取消し（キャンセル）と変更をされる場合は、本システムから予約の取消し（キャンセル）と変更の手続きを行うことができます。</p>
            <p className="u_mb20">〔会員様の場合〕<br />ログイン後に、予約の取消し（キャンセル）と変更を行うことができます。</p>
            <p>［非会員様の場合］<br />ご登録時の「メールアドレス」と、予約完了後に発行される「お問い合わせ番号」にて予約の取消し（キャンセル）と変更を行うことができます。<br />注） 宿泊プランによりましては、予約の取り消し（キャンセル）を受けられない場合がございますので、予めご了承をください。</p>
          </section>
          <section className="u_mbMedium">
            <h2 className="c_headingLv3">第10条（違約金）</h2>
            <p className="u_mb20">当社は利用者に帰すべき事由によりご予約が取り消された場合、各グループホテルの宿泊約款に定める違約金を申し受けます。なお、詳細は当該ホテルまたは施設までお電話にてお問合せください。</p>
            <h3 className="c_headingLv4">ロイヤルパークホテルズキャンセル違約金一覧</h3>
            <COverflowTable>
              <table className="c_fixedTable">
                <tbody>
                  <tr>
                    <th rowSpan={3} className="gold">
                    ロイヤルパークホテルズ</th>
                    <th>契約人数</th>
                    <th>解除通知日</th>
                    <th>不泊</th>
                    <th>当日</th>
                    <th>前日</th>
                    <th>7～2日前</th>
                    <th>21～8日前</th>
                  </tr>
                  <tr>
                    <td>一般</td>
                    <td>9名まで</td>
                    <td>100％</td>
                    <td>100％</td>
                    <td>80％</td>
                    <td>40％</td>
                    <td>－</td>
                  </tr>
                  <tr>
                    <td>団体</td>
                    <td>10名以上</td>
                    <td>100％</td>
                    <td>100％</td>
                    <td>80％</td>
                    <td>40％</td>
                    <td>20％</td>
                  </tr>
                </tbody>
              </table>
            </COverflowTable>
          </section>
          <section className="u_mbMedium">
            <h2 className="c_headingLv3">第11条（予約申込）</h2>
            <p className="u_mb20">1. 本システムを利用しての予約申込の場合、希望する商品の予約申込フォームへ必要とされるデーターを全て正確に入力してください。<br />2. 予約申込フォームへのデーター入力に不備があった場合、その予約は無効となる場合があります。</p>
          </section>
          <section className="u_mbMedium">
            <h2 className="c_headingLv3">第12条（予約確認の方法）</h2>
            <p className="u_mb20">予約確認方法は、本システムでご確認をすることができます。</p>
            <p className="u_mb20">〔会員様の場合〕<br />ログイン後に、予約状況をご確認することができます。</p>
            <p className="u_mb20">〔非会員様の場合〕<br />
            ご登録時の「メールアドレス」と、予約完了後に発行される「お問い合わせ番号」にて予約状況をご確認することができます。</p>
          </section>
          <section className="u_mbMedium">
            <h2 className="c_headingLv3">第13条（お支払い方法）</h2>
            <p className="u_mb20">お支払い方法は宿泊施設によって異なります。詳細についてはご予約された宿泊施設へ電話連絡にてご確認ください。</p>
          </section>
          <section className="u_mbMedium">
            <h2 className="c_headingLv3">第14条（予約金について）</h2>
            <p className="u_mb20">予約金の有無、金額は宿泊施設によって異なります。詳細についてはご予約された宿泊施設へ電話連絡にてご確認ください。</p>
          </section>
          <section className="u_mbMedium">
            <h2 className="c_headingLv3">第15条（システム内容の変更）</h2>
            <p className="u_mb20">本システムの運営もしくは内容の変更は、当社が必要と判断した場合、事前にお客様に通知することなく変更を行う場合があります。本システムのご利用に際しましては、毎回必ず本規約をご確認ください。本規約内容の変更後は、変更後の内容のみが有効となり、変更前の内容は無効となります。</p>
          </section>
          <section className="u_mbMedium">
            <h2 className="c_headingLv3">第16条（システムの中断）</h2>
            <p className="u_mb20">当社は、次のいずれかに該当する場合、お客様への事前の通知や承諾なしに、本システムの一時的な利用の中断をおこなうことがあります。<br />
            1. 本システムの保守または工事をおこなう場合。<br />
            2. 天災、事変その他非常事態が発生し、または発生のおそれがあり、本システムの運営が困難となった場合。<br />
            3. 当社が、上記以外の事由により、本システムの一時的な中断が必要と判断した場合。</p>
          </section>
          <section className="u_mbMedium">
            <h2 className="c_headingLv3">第17条（取り扱い法令）</h2>
            <p className="u_mb20">本システムに関する取り扱いは、日本国内にて有効な法律に従います。</p>
          </section>
          <section className="u_mbMedium">
            <h2 className="c_headingLv3">第18条（本規約の有効）</h2>
            <p className="u_mb20">本規約は、平成18年10月3日より有効とします。なお、当社は社会経済情勢の変化ならびに諸般の事情で、本規約の改正と変更を、お客様への事前の通知無しに出来るものとします。</p>
          </section>
        </LWrap>
      </section>
    </Layout>
  )
}

export default SubPage;

export const query = graphql`
  query ($slug: String!){
    markdownRemark(fields: {slug: {eq: $slug}}) {
      html
      frontmatter {
        title
        description
      }
    }
  }
`